<template>
	<div class="merchantProtocol">
		<p class="big-title">粗门俱乐部产品服务协议</p>
		<p class="middle-title">协议须知：</p>
		<p>《粗门俱乐部产品服务协议》（以下简称“本协议”）是由粗门（包括但不限于小程序等）的运营方即小象蓝图（成都）科技有限责任公司与粗门俱乐部产品使用者达成的关于使用粗门俱乐部服务条款。</p>
		<p>在此特别提醒你认真阅读本协议条款，并请你审慎考虑并选择接受或不接受本协议。如果你点击“我已经阅读并同意”按钮（按钮的具体表述可能会做适当调整），即表示你已充分阅读、理解并自愿接受本协议。</p>
		
		<p class="middle-title">1. 协议内容和变更</p>
		<p>1.1 服务开通和协议生效。你申请使用粗门俱乐部服务时，在线接受本协议后立即生效，但本协议下的服务并不立即开通。你履行上传相关资质等义务，经粗门审核通过并向你发出服务开通通知（通知方式包括但不限于站内信、手机短信等）时，本协议下的粗门俱乐部服务正式开通。</p>
		<p>1.2 协议变更及生效。粗门有权变更（包括但不限于制定、修订、废止）本协议，将至少提前7日在粗门小程序内推送和公示，并载明生效日期。如你不接受变更，应当在公示期间内将线上的服务（含商品，下同）全部下架，本协议终止，同时将客户已经下单的服务（含商品）继续履约。粗门对于你选择的协议终止不负有违约责任或其他责任。</p>
		<p>1.3 本协议期限届满或本协议约定的其他终止情形发生时，本协议终止。除协议另有约定外，协议履行过程中，一方经书面通知对方且经双方协商一致后，可解除本协议。本协议终止或解除后：</p>
		<p>&emsp;1.3.1 粗门有权删除平台上已发布的你信息，且有权拒绝提供相关信息；</p>
		<p>&emsp;1.3.2 本协议终止前你已与用户达成的尚未履行完毕的订单，你应按照本协议继续履行相应义务；</p>
		<p>&emsp;1.3.3 你同意粗门保存你资质信息、商品信息、交易记录等相关资料。</p>
		<p>1.4 服务内容。你在遵守本协议及规则、不存在违约情形前提下，粗门提供粗门俱乐部产品日常维护、技术支持，保证产品的正常运作，若你存在违约行为，平台有权限制你产品部分或全部功能。</p>
		<p>1.5 产品服务费。你充分理解并确认，粗门有权就平台向你提供的各项服务收取费用，粗门通过向你提供综合性的线上推广及交易服务，收取支付金额的相应比例作为产品使用服务费，订单发生退款的，退款时收取产品服务费，后期粗门调整产品服务费时，至少提前7日在粗门APP内推送和公示，并载明生效日期。</p>
		<p>1.6 支付通道费。你使用粗门合作方提供的融合银行卡收单支付及网络支付等多个支付渠道的支付服务产品的费用。</p>
		<p>1.7 账号管理。粗门俱乐部没有独立的账号体系，属于粗门账号的其中产品功能，因此需要注销账号时，参照“粗门用户协议”的流程申请即可。若存在为服务的订单、上架中的服务（含商品）、未提现的资金则需要处理完成后才可以注销。----不处理不活跃的账号</p>

		<p class="middle-title">2.你的权利义务</p>
		<p>2.1 你应保证你为一家依据中国法律合法成立并有效存续的法人或其它主体，能够独立承担法律责任，并具有履行本协议所需的一切权利及能力，你因资质或提供的你信息而致本协议无法正常履行的，由此产生的一切结果由你负责。</p>
		<p>2.2 你承诺向粗门提交的证明材料合法、真实、准确详尽，否则你承诺独立承担因此导致的相应责任及后果。</p>
		<p>2.3 你承诺发布、编辑、更新的信息（包括但不限于文字、图片等）真实准确，并且不侵犯任何人的合法权益。</p>
		<p>2.4 你向用户提供服务（含商品）时，应确保与你信息一致，因你原因造成的用户投诉、经济损失等相关后果均由你承担。</p>
		<p>2.5 你应合法经营，且提供的服务（含商品）应符合国家法律、法规、规章的规定，如因你行为或你提供的服务（含商品）造成用户损失的，由你承担相应的赔偿责任，如粗门基于前述情况向消费者先行赔付的，粗门有权进行追偿。</p>
		<p>2.6 你应按照用户实际支付金额开具相应金额的发票。</p>
		<p>2.7 你应保证合作期间的服务（含商品）实际销售价格、在其他平台销售价格不低于在粗门发布的价格，且不得诱使用户改为使用现金或其他形式消费，否则粗门有权立即终止本协议并要求你按照本协议约定承担违约责任。</p>
		<p>2.8 对于虽已成交但并未实际消费的订单，你同意粗门向消费者退回相应款项而不需要你的同意。</p>
		<p>2.9 你同意用户通过平台发布的针对你的评论、照片等，纯属用户个人意见，与粗门立场无关，粗门不承担任何责任。</p>
		<p>2.10 你应按照服务约定向用户提供服务（含商品），否则由此引起用户投诉或给用户/ 粗门/ 粗门关联方/ 粗门合作方造成损失的，相应责任均由你承担，且粗门有权解除协议并要求你按本协议约定支付违约金。</p>

		<p class="middle-title">3.粗门的权利义务</p>
		<p>3.1 粗门应向你提供本协议约定的技术服务及其他相关服务，并依据本协议约定收取6‰支付通道管理费/次。</p>
		<p>3.2 为更好的推荐你的服务（含商品），粗门可通过适当的方式和渠道做相关宣传介绍，并进行相关推荐/ 推广。你对上述行为予以认可，并授权粗门/  粗门合作方就你提供的服务（含商品）名称、LOGO 、图片、文字、门店、优惠等信息进行使用。</p>
		<p>3.3 粗门有权对你在平台发布的内容、信息进行形式审核，必要时粗门有权依据法律法规、政策规定及平台规则拒绝发布( 如发布涉嫌黄、赌、毒及其他违法或者违反公序良俗的信息) 以及删除其违规内容。</p>
		<p>3.4 如用户就你服务（含商品）向粗门/投诉，粗门经核实后，有权要求你配合解决该投诉，在该投诉未解决之前，粗门有权冻结你在平台待提现的款项，禁止提现，直至你解决投诉。</p>
		
		<p class="middle-title">4.反商业贿赂条款</p>
		<p>4.1 总体要求。你应当严格遵守法律法规有关禁止商业贿赂行为规定，坚决拒绝商业贿赂、行贿及其他不正当商业行为的馈赠。</p>
		<p>4.2 商业贿赂定义。本协议所指的商业贿赂是指你为获取与粗门的合作及合作的利益，你或其单位工作人员给予粗门员工的一切精神及物质上直接或间接的馈赠，如现金、回扣、娱乐、旅游等。</p>
		<p>4.3 违规的责任。若你贿赂粗门任何员工，以图获取任何不正当商业利益或更特殊的商业待遇或不配合粗门查处其员工的受贿行为的，粗门将立即开除涉事员工，永久停止与你的一切合作，并依法对你采取诸如暂停支付所有应付账款的措施。情节严重、造成重大经济损失的，粗门将依法移交司法机关处理。</p>
		
		<p class="middle-title">5.附则</p>
		<p>5.1双方的保密义务。本协议任何一方均应对其获得的对方之业务、经营、财务状况和其他保密资料予以严格保密，不得以任何形式向任何第三方披露。否则，因此给其他方造成任何损失的，违约方应承担因此给守约方造成的全部损失。保密义务在协议有效期间及终止后始终有效，不因本协议其他条款整体或部分无效、被撤销而失效。</p>
		<p>5.2 与本协议有关的全部通知，双方可选择采用书面、邮件或站内通知任一种方式变更及确认。</p>
		<p>5.3 免责条款。因战争、自然灾害、政府行为、互联网系统故障、互联网通讯提供商故障等不可抗力因素或第三方原因导致粗门不能履行本协议项下义务，粗门无须承担责任。</p>
		<p>5.4 法律适用。本协议的订立、履行、解释、变更及争议解决均适用中华人民共和国大陆地区法律（不包括冲突法规则）。</p>
		<p>5.5 争议解决。约定管辖。对于因本协议产生的或者与本协议有关的争议，应友好协商解决，协商不成的，任何一方均有权提起诉讼，且双方一致同意由粗门住所地人民法院管辖。</p>
	</div>
</template>

<script>
export default {
	name: "Protocol",
	created(){
		document.title = `粗门俱乐部产品服务协议`;
	}
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.merchantProtocol{
	padding: px2rem(52);
	> p {
		font-size: px2rem(30);
		color: #1f2329;
		margin-block-start: .5em;
    	margin-block-end: .5em;
		line-height: px2rem(52);
		font-family: PingFangSC-Regular, PingFang SC, -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
			Helvetica, Segoe UI, Arial, Roboto, 'miui', 'Hiragino Sans GB',
			'Microsoft Yahei', sans-serif;
	}
	> .middle-title {
		font-weight: bold;
		margin-top: px2rem(80);
	}
	> .big-title {
		font-size: px2rem(58);
		font-weight: bold;
	}
}
</style>
